import React, { Component } from 'react';
import { Checkbox, Container, Divider, Header, Item, Rating } from 'semantic-ui-react'
import off_pistes from './off-pistes.json'
import Piste from './Piste'

class App extends Component {
  state = {
    off_pistes: off_pistes.sort((piste1,piste2) => (piste1.name === piste2.name ? 0 : (piste1.name < piste2.name ? -1 : 1))),
    sorted: false,
    selectedPiste: null
  }

  sort = (event, { checked }) => {
    if (checked) {
      this.setState({ sorted: true, off_pistes: off_pistes.sort((piste1,piste2) => (piste1.ski_difficulty === piste2.ski_difficulty ? 0 : (piste1.ski_difficulty < piste2.ski_difficulty ? 1 : -1))) })
      return
    } else {
      this.setState({ sorted: false, off_pistes: off_pistes.sort((piste1,piste2) => (piste1.name === piste2.name ? 0 : (piste1.name < piste2.name ? -1 : 1))) })
      return
    }
  }

  view = (id) => {
    this.setState({ selectedPiste: off_pistes.find(piste => (piste.id === id))})
  }

  close = () => {
    this.setState({ selectedPiste: null })
  }

  render() {
    const { off_pistes, selectedPiste } = this.state
    return (
      <div className="App">
        <Container style={{ margin: '3em' }}>
          <Header as='h1'>Off-pistes of Chamonix</Header>
          <Container textAlign='right'>
            <Checkbox toggle label="Sort by difficulty" onChange={this.sort} checked={this.state.sorted}/>
          </Container>
          <Divider />
          <Piste piste={selectedPiste} close={this.close} />
          <Container>
            <Item.Group divided>
              {off_pistes.map(({id,name,time_required, rating,ski_difficulty,short_description,equipment}) => (
                <Item key={id}>
                  <Item.Content>
                    <Item.Header as='a' onClick={this.view.bind(this,id)}>
                      {name} <Rating icon='star' defaultRating={rating} maxRating={5} disabled />
                    </Item.Header>
                    <Item.Meta>
                      {time_required && (<span>{time_required} -</span>)}
                      <span>Ski difficulty : {ski_difficulty}/5</span>
                    </Item.Meta>
                    <Item.Description>
                      {short_description}
                    </Item.Description>
                    {equipment && (
                      <Item.Extra>
                        Equipement: {equipment}
                      </Item.Extra>
                    )}
                  </Item.Content>
                </Item>
              ))}
            </Item.Group>
          </Container>
        </Container>
      </div>
    );
  }

}

export default App;
