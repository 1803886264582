import React from 'react'
import { Container, Header, Modal, Rating } from 'semantic-ui-react'
import { Map, Polyline, TileLayer } from 'react-leaflet'

function center(a) {
  let minLat = a[0][1]
  let maxLat = a[0][1]
  let minLng = a[0][0]
  let maxLng = a[0][0]
  a.forEach(function(item) {
    if (item[1] < minLat) {
      minLat = item[1]
    }
    if (item[1] > maxLat) {
      maxLat = item[1]
    }
    if (item[0] < minLng) {
      minLng = item[0]
    }
    if (item[0] < maxLng) {
      maxLng = item[0]
    }
  });
  return {lat: (minLat+maxLat)/2, lng: (minLng+maxLng)/2}
}

const Piste = ({piste, close}) => (
  <Modal open={piste !== null} onClose={close}>
    {piste !== null && (<>
      <Modal.Header as='h2'>{piste.name}</Modal.Header>
      <Modal.Content>
        <Header as='h3'>Rating</Header>
        <Rating icon='star' defaultRating={piste.rating} maxRating={5} disabled />
        {piste.time_required && (<>
          <Header as='h3'>Rating</Header>
          <Container as='p'>{piste.time_required}</Container>
        </>)}
        <Header as='h3'>Ski difficulty</Header>
        <Container as='p'>{piste.ski_difficulty}/5</Container>
        <Header as='h3'>Description</Header>
        <Modal.Description as='p'>{piste.description}</Modal.Description>
        <Header as='h3'>Map</Header>
        <Map style={{ height: '40vh' }} center={center(piste.geo_data.coordinates[0])} zoom={14}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
        />
        <Polyline positions={piste.geo_data.coordinates[0].map(a => [a[1],a[0]])}/>
        </Map>
      </Modal.Content>
    </>)}
  </Modal>
)

export default Piste